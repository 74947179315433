body {
	margin: 0;
	padding: 0;
	font: 11px/14px Verdana, Arial, sans-serif;
	color: #333;
	background-color: #fff;
}
h1 {
	font: lighter 17px/20px Arial, sans-serif;
	color: #8c0808;
	border-bottom: solid 1px #ebebeb;
	padding-bottom: 0.6em;
	margin-bottom: 0.8em;
}
h1 .subhead {
	font-size: 11px;
}
h2 {
	font: bold 14px/17px Arial, sans-serif;
	color: #ab3923;
}
h3 {
	font: bold 12px/15px Verdana, Arial, sans-serif;
	color: #036;
	padding-top: 1em;
}
H4 {
	font: bold 11px/14px Verdana, Arial, sans-serif;
	color: #333;
}
p {

}

hr {
	border: 0;
	color: #ebebeb;
	background-color: #ebebeb;
	height: 1px;
	margin: .5em 0 1em;
}
a {
	color: #069;
	text-decoration: none;
	outline: none;
}
a:visited {
	color: #069;
	text-decoration: none;
}
img {
	border: 0;
}

table.dataTable td.numeric {
	text-align:center;
}

table.dataTable li {
	margin-left: 40px;
}

/* misc */

.noPrint { display: none !important; }
.toolbar { display: none; }
ul#mainTabNav,.sectionNav { display: none; }
ul#SignInArea { display: none; }

table.dataTable th, table.dataTable td {
	padding: 5px 9px;
	text-align: left;
}
table.dataTable th {
	border-bottom: solid 1px #ccc;
}

/* Bar graph stuff */
img.sbg_Bar
{
	width: 80%;
	left: 10%;	
}

td.sbg_BarBackground
{

}

td.sbg_BarLabel
{
	padding: .2em;
	text-align: center;
	color: #666666;
	font-size: 9px;
	background-color: #ffffff;
}

div.sbg_BarValueText
{
	font-weight: bold;
	font-size: small;
	color: #272e14;
	padding-top: 2px;
}

table.sbg_Container
{
	width: 100%;
}

table.sbg_Table
{
	width: 100%;
	border-collapse:collapse;	
	table-layout: fixed;	
}

table.sbg_Table td
{
	border: solid 1px #ccc;
}

/*
 * We need the top/bottom borders to be there but disabled
 * because otherwise the graph will accumulate pixel error
 * when printed from IE.
 */
table.sbg_Table td.sbg_Scale
{
	text-align: right;
	font-size: 10px;
	padding-left: 1em;
	padding-right: 10.5em;
	border-left: 0px;
	border-top: solid 1px #fff;
	border-bottom: solid 1px #fff;
}

table.sbg_Table td.sbg_BarLabel
{
	padding: .2em;
	text-align: center;
	font-size: 8px;
	border-left: hidden;
	border-right: hidden;
	border-bottom: hidden;
}

th.sbg_Titlebar
{
	text-align: left;
	padding: 5px 9px;
}

/* score report */
div.msghead1, div.msghead2 {
	border-bottom: 1px solid black;
	margin-top: 10px;
}

a#LinkRes {
	display: none;
}

div#AltResponseText {
	display: block;
}

.newPageBefore { page-break-before: always; }
.newPageAfter { page-break-after: always; }
.noPageBreak { page-break-inside: avoid; }

.organizer  {
    page-break-after: always; 
}

#divEssayContainer #divRightColumn { display: none; }

#divEssayInfo #Master_Main_divDraft { display: block !important; margin-top: 5px; font-size: 14px; }

#body .wrapper { border: none !important; }